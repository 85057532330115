const API_PATH = {
  // エントリー割引・制限時間に関するパス
  ENTRY_DISCOUNT_TIME_LIMIT: '/entry_discount_time_limit',
  ENTRY_SETTLEMENT_TIME_LIMIT: '/entry_settlement_time_limit',
  ENTRY_CAMPAIGN: '/entry_campaign',

  CHORTS: '/cohorts',
  CHORTS_LATEST: '/cohorts/latest',
  WHOLE_COHORTS: '/whole_cohorts',
  WHOLE_COHORTS_FIRST_OPENED: '/whole_cohorts/first_opened',
  BLOG_ARTICLES: '/blog_articles',
  PROMOTION_CURRENT: '/promotions/current',
  COURSE: '/courses',
  MINI_COURSE: '/mini_courses',
  COURSE_ENTRY: '/courses/entries',
  TRIAL_ENTRY: '/trial_entries',
  PACK: '/packs',
  BRIEFING_ENTRY: '/briefing_entries',
  MOOCRES_BRIEFING_ENTRY: '/moocres_briefing_entries',
  JOBS: '/jobs',
  SEMINAR_ENTRY: '/seminar_entries',
  BIZ: '/biz',
  CONTACT: '/contact',
  YAHOO_ENTRY: '/external/yahoo_entries',
  YAHOO_NOTIFICATION: '/yahoo_notification_receivers',
  YAHOO_PASSWORD_FORGOT: '/external/yahoo_user_password',
  YAHOO_CONTACT_BIZ: '/external/contact',
  TOPICS: '/topics',
  USER_CURRENT: '/users/current',
  SESSION: '/session',
}

export { API_PATH }
