import { API_PATH } from '~/utility/constants/api-path'
import { queryConfigToString } from '~/utility/utility'

// コース一覧の取得
export const getCourses = (api: any, queryConfig: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.COURSE}` + queryConfigToString(queryConfig))
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// 申込みの割引期限の取得
export const getEntryDiscountTimeLimit = (api: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.ENTRY_DISCOUNT_TIME_LIMIT)
      .then((response: any) => {
        // 考慮の余地あり Promise.all を使う箇所との兼ね合いなので、要調査
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// ユーザに付与されたキャンペーンコード情報を取得
export const getEntryCampaign = (api: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.ENTRY_CAMPAIGN)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// ブートキャンプの直近の開催期を取得
export const getLatestCohort = (api: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.CHORTS_LATEST)
      .then((response: any) => {
        // 考慮の余地あり Promise.all を使う箇所との兼ね合いなので、要調査
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// 開催中の受け放題の一番最初のものを取得
export const getFirstOpenedWholeCohort = (api: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.WHOLE_COHORTS_FIRST_OPENED)
      .then((response: any) => {
        // 考慮の余地あり Promise.all を使う箇所との兼ね合いなので、要調査
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// 受け放題へのエントリー
export const postWholeEntry = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.WHOLE_COHORTS}/entries`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// 開催期の一覧を取得
export const getCohorts = (api: any, queryConfig: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.CHORTS + queryConfigToString(queryConfig))
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// キャンペーン対象の開催期の一覧を取得
export const getPromotionCohorts = (api: any, screenName: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.COURSE}/${screenName}/promotion_target_cohorts`)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject((response?.data))
        }
      })
      .catch((error: any) => reject(error))
  })
}

// キャンペーン対象のプランの一覧を取得
export const getPromotionPlans = (api: any, screenName: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.COURSE}/${screenName}/promotion_target_plans`)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject((response?.data))
        }
      })
      .catch((error: any) => reject(error))
  })
}

// ブログ記事取得
export const getBlogArticles = (api: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.BLOG_ARTICLES)
      .then((response: any) => {
        // 考慮の余地あり Promise.all を使う箇所との兼ね合いなので、要調査
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// プロモーション（キャンペーン）取得
export const getCurrentPromotion = (api: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(API_PATH.PROMOTION_CURRENT)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// コースの情報取得
export const getCourse = (api: any, path: any, queryConfig: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.COURSE}/${path}` + queryConfigToString(queryConfig))
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// セットの情報取得
export const getPack = (api: any, path: any, queryConfig: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.PACK}/${path}` + queryConfigToString(queryConfig))
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// セットのプランの情報取得
export const getPackPlans = (api: any, path: any, queryConfig: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.PACK}/${path}/pack_plans` + queryConfigToString(queryConfig))
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// コースへの申込みを行う
export const postCourseEntry = (api: any, path: any, body: any, persist = true) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.COURSE}/${path}/entries?persist=${persist}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// セットへの申込みを行う
export const postPackEntry = (api: any, path: any, body: any, persist = true) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.PACK}/${path}/entries?persist=${persist}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// トレーニングへの申込みを行う
export const postMiniCourseEntry = (api: any, path: any, body: any, persist = true) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.MINI_COURSE}/${path}/entries?persist=${persist}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// 無料体験の申込みを行う
export const postTrialCourseEntry = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.TRIAL_ENTRY}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// 動画説明会への申し込みを行う
export const postBriefingEntry = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.BRIEFING_ENTRY}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// ムークリの動画説明会への申し込みを行う
export const postMoocresBriefingEntry = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.MOOCRES_BRIEFING_ENTRY}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// LPの動画説明会への申し込みを行う
export const postLpSCoverBriefingEntry = (api: any, path: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.BRIEFING_ENTRY}/${path}/lp_entries`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// ショートLPの動画説明会への申し込みを行う
export const postLpSProgrammingCoverBriefingEntry = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.BRIEFING_ENTRY}/lps`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// メンター講師への申し込みを行う
export const postJobsCandidateEntry = (api: any, path: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.JOBS}/${path}/candidates`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// メンター講師への申し込みに関する情報の取得
export const getJobsCandidateFormInfo = (api: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.JOBS}/candidates`)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// フォーム中の項目の取得
export const getTrialMiniCourseSchedule = (api: any, path: any, query = {}) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.MINI_COURSE}/${path}/schedules`, {params: query})
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// 無料相談の空き状況の取得
export const getTrialMiniCourseScheduleTable = (api: any, path: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.MINI_COURSE}/${path}/view_appointment_form`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// メンター講師の情報を送信する
export const postJobsCandidateProfile = (api: any, token: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.JOBS}/candidates/${token}/profile`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// トレーニングの無料体験の相談スケジュールを送信する
export const postTrialMiniCourseSchedule = (api: any, path: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.MINI_COURSE}/${path}/schedules`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// メンター講師のプロフィール登録
export const getCheckJobsProfileCandidateStatus = (api: any, token: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.JOBS}/candidates/${token}/profile_status`)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// 動画説明会で使用されるスクリプト情報の取得
export const getInfoForBriefingEntryScripts = (api: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.BRIEFING_ENTRY}/script_info`)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// ショートLPの動画説明会で使用されるスクリプト情報の取得
export const getInfoForLpSBriefingEntryScripts = (api: any, path: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`/script/${API_PATH.BRIEFING_ENTRY}/lps/${path}`)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// -- セミナー動画申し込み -- //
export const postSeminarEntry = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.SEMINAR_ENTRY}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// [企業研修] 開講コースの情報を取得
export const getBizTeam = (api: any, team: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.BIZ}/teams/${team}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// -- お問合せ送信 -- //
export const postContact = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.CONTACT}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 204) {
          resolve(response?.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// お問い合わせのカテゴリーを取得
export const getContactCategoryType = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.CONTACT}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// [LYTA] LYTAの申込みを行う
export const postYahooEntry = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.YAHOO_ENTRY}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 204) {
          resolve(response?.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// [LYTA] 次回開催のお知らせ情報を取得
export const postYahooNotification = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.YAHOO_NOTIFICATION}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 204) {
          resolve(response?.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// Topicsお知らせの情報を取得
export const getTopics = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.TOPICS}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// ログインしているユーザの情報を取得
export const getCurrentUser = (api: any) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${API_PATH.USER_CURRENT}`)
      .then((response: any) => {
        if (response?.status === 200 || response?.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  })
}

// ログインする
export const postSession = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.SESSION}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  });
}

// [LYTA] パスワードをリセットする
export const postYahooPasswordForgot = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api
      .post(`${API_PATH.YAHOO_PASSWORD_FORGOT}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  });
}

// 法人向けのお問い合わせを送信
export const postContactBiz = (api: any, body: any) => {
  return new Promise((resolve, reject) => {
    api.post(`${API_PATH.YAHOO_CONTACT_BIZ}`, body)
      .then((response: any) => {
        if (response?.status === 200 || response.status === 304) {
          resolve(response.data)
        } else {
          reject(response?.data)
        }
      })
      .catch((error: any) => reject(error))
  });
}
